import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
	FaEnvelope,
	FaPhone,
	FaFacebook,
	FaTwitter,
	FaLinkedin,
	FaInstagram,
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
	return (
		<footer className="footer">
			<Container>
				<Row>
					<Col md={4} className="footer-section">
						<h4>Contact Us</h4>

						<p>
							<FaEnvelope /> Email: registrarpeshi@jntugv.edu.in
						</p>
						<p>
							<FaPhone /> Phone: 089222 77388
						</p>
					</Col>
					<Col md={4} className="footer-section">
						<h4>Quick Links</h4>
						<ul>
							<li>
								<a href="#home">Home</a>
							</li>
							<li>
								<a href="#about">About Us</a>
							</li>
							<li>
								<a href="#features">Features</a>
							</li>
						</ul>
					</Col>
					<Col md={4} className="footer-section">
						<h4>Location</h4>
						JNTU-Gurajada College of Engineering Vizianagaram, Nh 43, Dwarapudi (Post), Vizianagaram, Andhra Pradesh, Andhra Pradesh 535003.
						
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="text-center footer-copyright">
							&copy;2024 JNTU GV Student Information System. Powered by Eddrix.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
