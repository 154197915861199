import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./LandingPage.css";
import About from "../About/About";
import Features from "../Features/Features";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CollegeInfo from "../JNTUGV/Collegeinfo";
import VisionSection from "../Vision/Vision";
import MissionSection from "../Mission/Mission";

const Landingpage = () => {
	const [activeSection, setActiveSection] = useState("home");


	

	useEffect(() => {
		const handleScroll = () => {
			const sectionOffsets = {
				home: document.getElementById("home")?.offsetTop,
				about: document.getElementById("about")?.offsetTop,
				features: document.getElementById("features")?.offsetTop,
				vision: document.getElementById("vision")?.offsetTop,
				mission: document.getElementById("mission")?.offsetTop,
			};

			const scrollPosition = window.scrollY + 100;
			let newActiveSection = "home";

			for (const section in sectionOffsets) {
				if (
					sectionOffsets[section] &&
					scrollPosition >= sectionOffsets[section]
				) {
					newActiveSection = section;
				}
			}

			setActiveSection(newActiveSection);
		};

		const delayExecution = () => {
			if (
				document.getElementById("home") &&
				document.getElementById("about") &&
				document.getElementById("features") &&
				document.getElementById("vision") &&
				document.getElementById("mission")
			) {
				handleScroll();
			} else {
				setTimeout(delayExecution, 100);
			}
		};

		delayExecution();

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<Navbar
				bg="light"
				data-bs-theme="light"
				className="bg-main justify-content-around"
				fixed="top"
				expand="lg">
				<Container>
					<Navbar.Brand href="#">Student Information System</Navbar.Brand>

					<Navbar.Toggle aria-controls="navbarScroll" />

					<Navbar.Collapse id="navbarScroll">
						<Nav className="navbar-nav">
							<Nav.Item>
								<Nav.Link href="#home" active={activeSection === "home"}>
									Home
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="#about" active={activeSection === "about"}>
									About
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									href="#features"
									active={activeSection === "features"}>
									Features
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="#vision" active={activeSection === "vision"}>
									Vision
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="#mission" active={activeSection === "mission"}>
									Mission
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<div className="main-content">
				<div id="home">
					<CollegeInfo />
				</div>
				<div id="about">
					<About />
				</div>
				<div id="features">
					<Features />
				</div>
				<div id="vision">
					<VisionSection />
				</div>
				<div id="mission">
					<MissionSection />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Landingpage;
