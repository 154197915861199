import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [token,setToken] = useState(null);
	const [userRole,setUserRole] = useState(null);
	const level = "easy";

	const instructorapiuri = "https://instructor-backend.onrender.com";
	const studentapiuri = "https://studentbackend-x2x6.onrender.com";
	useEffect(() => {
		const storedToken = localStorage.getItem("token");
		if (storedToken) {
			setToken(storedToken);
		}
		const storedRole = localStorage.getItem("role");
		if (storedRole) {
			setUserRole(storedRole);
		}
		setIsLoading(false);
	}, []);

	const handleLogin = (token,role) => {
		setToken(token);
		setUserRole(role);
		localStorage.setItem("token", token);
		localStorage.setItem("role", role);
	};
	
	const handleLogout = () => {
		setToken(null);
		setUserRole(null);
		localStorage.removeItem("token");
		localStorage.removeItem("role")
	};

	
	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<UserContext.Provider
			value={{
				handleLogin,
				handleLogout,
				token,
				instructorapiuri,
				studentapiuri,
				userRole,
				level,
			}}>
			{children}
		</UserContext.Provider>
	);
};
