import React from "react";
import { Container } from "react-bootstrap";
import aboutImage from "./Vision.png";
import './Vision.css';
import ScrollAnimation from "react-animate-on-scroll";

function VisionSection() {
	return (
		<div className="about-container">
			<ScrollAnimation animateIn="wobble" initiallyVisible={true}>
				<h2 className="section-header">Vision</h2>
			</ScrollAnimation>
			<Container>
				<div className="vis-cont">
					<div>
						<img src={aboutImage} alt="About Us" />
					</div>
					<div className="abt-des">
						<p>
							To be the forefront of technological innovation in academia,
							envisioning a JNTU-GV where the Student Information Portal
							transforms the landscape of data management. We strive to create a
							seamlessly integrated platform that not only centralizes student
							information but also adapts to evolving educational needs,
							fostering efficiency, transparency, and a technologically advanced
							academic environment
						</p>
						
					</div>
				</div>
			</Container>
		</div>
	);
}

export default VisionSection;
