import React from "react";
import { Container } from "react-bootstrap";
import aboutImage from "./Mission.png";
import './Mission.css';
import ScrollAnimation from "react-animate-on-scroll";

function MissionSection() {
	return (
		<div className="mission-container">
			<ScrollAnimation animateIn="wobble" initiallyVisible={true}>
				<h2 className="section-header">Mission</h2>
			</ScrollAnimation>
			<Container>
				<div className="abt-cont">
					<div>
						<img src={aboutImage} alt="About Us" />
					</div>
					<div className="abt-des">
						<p>
							Our mission at the JNTU-GV Student Information Portal is rooted in
							a commitment to revolutionize the very fabric of student data
							management. We recognize the imperative need for a platform that
							goes beyond the conventional, a solution that not only centralizes
							information but acts as a catalyst for administrative efficiency
							and academic progress.
						</p>
						<p>
							The core of our mission is to provide a user-friendly, secure, and
							highly customizable Student Information Portal. We aim to empower
							administrators, faculty, and staff with real-time access to
							comprehensive student profiles, facilitating informed
							decision-making and streamlined administrative processes.
						</p>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default MissionSection;
