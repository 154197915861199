import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import './Features.css';


const Features = () => {
	return (
		<div className="features-cont">
			<h2 className="section-header">Features</h2>
			<Container>
				<Row className="features-items">
					<Col md={3}>
						<Card
							style={{
								backgroundColor: "pink",
								borderRadius: "10px",
								padding: "20px",
								margin: "20px",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
								transition: "transform 0.3s ease-in-out",
							}}>
							<h3>Customizable Reports</h3>
							<p>
								Empowers management to generate custom reports by selecting
								specific fields and data parameters, ensuring flexibility and
								relevance in report submissions.
							</p>
						</Card>
					</Col>
					<Col md={3}>
						<Card
							style={{
								backgroundColor: "pink",
								borderRadius: "10px",
								padding: "20px",
								margin: "20px",

								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
								transition: "transform 0.3s ease-in-out",
							}}>
							<h3>Real-time Data Accessibility</h3>
							<p>
								Enables instant access to student data, allowing faculty and
								administrators to retrieve up-to-date information whenever
								needed.
							</p>
						</Card>
					</Col>
					<Col md={3}>
						<Card
							style={{
								backgroundColor: "pink",
								borderRadius: "10px",
								padding: "20px",
								margin: "20px",
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
								transition: "transform 0.3s ease-in-out",
							}}>
							<h3>Efficient Administrative Tools</h3>
							<p>
								Equips administrators with tools for efficient data management,
								simplifying tasks such as enrollment, record updates, and report
								submissions.
							</p>
						</Card>
					</Col>
					<Col md={3}>
						<Card
							style={{
								backgroundColor: "pink",
								borderRadius: "10px",
								padding: "20px",
								margin: "20px",

								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
								transition: "transform 0.3s ease-in-out",
							}}>
							<h3>Role-based Access Control</h3>
							<p>
								Implements role-based access control, ensuring that only
								authorized personnel can access sensitive data and perform
								administrative tasks.
							</p>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Features;
