import React from "react";
import { Image } from "antd";
import './Style.css';
import logo from "./../Images/jntu-logo.png";
import { Link } from "react-router-dom";


const CollegeInfo = () => {

    
	return (
		<div className="college-info-main">
			<div className="logo-container">
				<div className="logo-image">
					<Image src={logo} preview={false} alt="College Logo" />
					<div className="college-name">
						JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY - GURAJADA <br></br>{" "}
						VIZIANAGARAM
					</div>
					<div></div>

					<div className="project-name">Student Information System</div>
				</div>
			</div>

			<div className="clg-description">
				Efficient Student Data Management System for Educational Excellence
			</div>
			<div className="btn-logins">
				<div className="login-btn">
					<Link to={"https://student.jntugv.edu.in/login"}>
						Login As Student
					</Link>
				</div>
				<div className="login-btn">
					<Link to={"https://eadmin.jntugv.edu.in/login"}>Login As Admin</Link>
				</div>
			</div>
		</div>
	);
};

export default CollegeInfo;
