import './App.css';
import Landingpage from "./Components/LandingPage/LandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from './Components/UserContext';


function App() {
  return (
		<div className="main">
			<UserProvider>
				<Router>
					<Routes className="dd">
						<Route path="" element={<Landingpage />} />
					</Routes>
				</Router>
			</UserProvider>
		</div>
	);
}

export default App;
