import React from "react";
import { Container } from "react-bootstrap";
import aboutImage from "./aboutimg.png";
import './About.css';
import ScrollAnimation from "react-animate-on-scroll";

function AboutSection() {
	return (
		<div className="about-container">
			<ScrollAnimation animateIn="wobble" initiallyVisible={true}>
				<h2 className="section-header">About Us</h2>
			</ScrollAnimation>
			<Container>
				<div className="abt-cont">
					<div>
						<img src={aboutImage} alt="About Us" />
					</div>
					<div className="abt-des">
						<p>
							Welcome to the heart of technological advancement at Jawaharlal
							Nehru Technological University, Gurajada Vizianagaram (JNTU-GV).
							The JNTU-GV Student Information System is a testament to our
							commitment to providing an advanced, user-friendly, and
							centralized platform for managing student information.
						</p>
						<p>
							Our mission is to provide a cutting-edge, user-friendly solution
							that empowers students and faculty members alike. Our journey
							doesn't end with the current capabilities of the portal. We are
							committed to continuous improvement, regularly updating and
							expanding functionalities to stay ahead of the evolving
							requirements of JNTU-GV.
						</p>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default AboutSection;
